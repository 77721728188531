import { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { FormData, ResetPageState } from 'resetPassword/resetTypes';
import { resetPassword } from 'api/authApi';
import { Button } from '@whoop/web-components';
import { Collapse } from '@material-ui/core';
import { PASSWORD_STRENGTH, PASSWORD_LENGTH_STRENGTH } from 'register/constants';
import PasswordStrengthSpec from 'register/components/passwordStrengthSpec';
import { UseStatePropType } from 'types/useStatePropType';
import { useLocation } from 'react-router';
import styles from '../resetPassword.module.scss';

type ResetPasswordFormProps = {
  setPageState: UseStatePropType<ResetPageState>;
};

function ResetPasswordForm({ setPageState }: ResetPasswordFormProps) {
  const { pathname } = useLocation();
  const [token, setToken] = useState('');

  useEffect(() => {
    setToken(pathname.replace('/forgot/reset/', ''));
  }, [pathname]);

  const {
    register,
    handleSubmit,
    watch,
    formState: { isValid, errors, dirtyFields },
    setError,
    clearErrors,
  } = useForm<FormData>({
    mode: 'onChange',
  });

  const [showNewPassword, setNewShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState<boolean>(false);

  const watchPassword = watch('newPassword', '');
  const watchConfirmPassword = watch('confirmPassword', '');

  const passwordValidationError = errors?.newPassword
    || (dirtyFields.newPassword && watchPassword.length === 0);
  const passwordInputClass = `${styles.inputField} ${passwordValidationError && styles.inputFieldInvalid
  }`;

  const [passwordStrength, setPasswordStrength] = useState(
    PASSWORD_STRENGTH.WEAK,
  );
  const [passwordCharacterVerification, setPasswordCharacterVerification] = useState(false);
  const [passwordLengthVerification, setPasswordLengthVerification] = useState(false);

  const verifyPasswordsMatch = () => {
    if (watchPassword !== watchConfirmPassword) {
      setError('confirmPassword', { message: 'Your passwords do not match' });
    }
  };

  useEffect(() => {
    setPasswordLengthVerification(
      watchPassword.length >= PASSWORD_LENGTH_STRENGTH,
    );

    // Contains a number or symbol
    setPasswordCharacterVerification(
      /[0-9!%@#$^*?_~&()]/.test(watchPassword),
    );
    const calculatePasswordStrength = () => {
      let strLvl = 1;

      if (passwordLengthVerification) {
        strLvl += 1;
      }

      if (passwordCharacterVerification) {
        strLvl += 1;
      }

      switch (strLvl) {
        case 2:
          return PASSWORD_STRENGTH.AVERAGE;
        case 3:
          return PASSWORD_STRENGTH.STRONG;
        case 1:
        default:
          return PASSWORD_STRENGTH.WEAK;
      }
    };
    setPasswordStrength(calculatePasswordStrength);
  }, [
    watchPassword,
    passwordCharacterVerification,
    passwordLengthVerification,
  ]);

  const onSubmit: SubmitHandler<FormData> = async ({
    newPassword,
  }) => {
    try {
      await resetPassword(token, newPassword);
      setPageState(ResetPageState.Reset);
    } catch (error) {
      setError('confirmPassword', {
        message: 'Something went wrong trying to reset your password, please try again',
      });
    }
  };

  const showNewPasswordOnAction = () => {
    setNewShowPassword((prevState) => !prevState);
  };

  const showConfirmPasswordOnAction = () => {
    setConfirmShowPassword((prevState) => !prevState);
  };

  return (
    <>
      <div className={styles.infoText}>
        <h1>Update password</h1>
        <p>{`Must include a symbol or number and have at least ${PASSWORD_LENGTH_STRENGTH} characters.`}</p>
      </div>
      <form className={styles.formContainer} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.passwordWrap}>
          <label htmlFor="newPassword" id="newPassword" className={styles.inputFieldLabel}>
            New Password
            <input
              aria-label="newPassword"
              type={showNewPassword ? 'text' : 'password'}
              className={passwordInputClass}
              data-testid="password-input"
              {...register('newPassword', { required: true, minLength: PASSWORD_LENGTH_STRENGTH })}
              onFocus={() => clearErrors()}
            />
          </label>
          <button
            type="button"
            aria-label="showNewPassword"
            className={styles.showPasswordButton}
            onClick={showNewPasswordOnAction}
            onKeyDown={showNewPasswordOnAction}
          >
            {showNewPassword ? 'Hide' : 'Show'}
          </button>
          <Collapse in={dirtyFields.newPassword}>
            <div className={styles.passwordStrength}>
              <p className={styles.level}>
                {`Password Strength: ${passwordStrength}`}
              </p>
              <PasswordStrengthSpec
                label={`At least ${PASSWORD_LENGTH_STRENGTH} characters`}
                success={passwordLengthVerification}
              />
              <PasswordStrengthSpec
                label="Contains a number or symbol (ex: #, $, , !)"
                success={passwordCharacterVerification}
              />
            </div>
          </Collapse>
        </div>
        <div className={styles.passwordWrap}>
          <label htmlFor="confirmPassword" id="confirmPassword" className={styles.inputFieldLabel}>
            Confirm Password
            <input
              aria-label="confirmPassword"
              type={showConfirmPassword ? 'text' : 'password'}
              className={passwordInputClass}
              data-testid="confirm-input"
              {...register('confirmPassword', { required: true })}
              onFocus={() => clearErrors()}
              onBlur={verifyPasswordsMatch}
            />
          </label>
          <button
            type="button"
            aria-label="showConfirmPassword"
            className={styles.showPasswordButton}
            onClick={showConfirmPasswordOnAction}
            onKeyDown={showConfirmPasswordOnAction}
          >
            {showConfirmPassword ? 'Hide' : 'Show'}
          </button>
        </div>
        <div className={styles.invalidMessage}>
          {errors?.confirmPassword && errors.confirmPassword.message}
        </div>

        <Button
          label="Update Password"
          variant="primary"
          theme="enterprise"
          type="submit"
          data-testid="resetBtn"
          className={styles.updatePassword}
          disabled={!isValid || watchPassword !== watchConfirmPassword}
        />
      </form>
      <div className={styles.requestAccess}>
        Need access?
        {' '}
        <a data-testid="request-access-now" href="/request-access">Request access now.</a>
      </div>
    </>
  );
}

export default ResetPasswordForm;
