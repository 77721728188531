import { useState, useEffect } from 'react';
import { Button } from '@whoop/web-components';
import { useForm } from 'react-hook-form';
import { LockOutlined } from '@material-ui/icons';
import { Tooltip, Collapse } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { UseStatePropType } from 'types/useStatePropType';
import {
  PAGE_STATE,
  EMAIL_LOCK_TOOLTIP,
  PASSWORD_STRENGTH,
  PASSWORD_LENGTH_STRENGTH,
} from '../constants';
import styles from '../register.module.scss';
import PasswordStrengthSpec from './passwordStrengthSpec';

type CreateAccountProps = {
  setStep: UseStatePropType<string>;
  email: string;
  setPassword: UseStatePropType<string>;
};

type SubmitValues = {
  email: string;
  password: string;
  confirmPassword: string;
};

function CreateAccount({ setStep, email, setPassword }: CreateAccountProps) {
  // React hook form
  const {
    register,
    handleSubmit,
    watch,
    formState: { isValid, errors, dirtyFields },
    setError,
  } = useForm({
    mode: 'onChange',
  });

  const emailValidationError = !email;
  const emailInputClass = `${styles.inputField} ${emailValidationError && styles.inputFieldInvalid}`;

  const watchPassword = watch('password', '');
  const watchConfirmPassword = watch('confirmPassword', '');

  const passwordValidationError = errors?.password
    || (dirtyFields.password && watchPassword.length === 0);
  const passwordInputClass = `${styles.inputField} ${passwordValidationError && styles.inputFieldInvalid}`;

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(
    PASSWORD_STRENGTH.WEAK,
  );
  const [passwordCharacterVerification, setPasswordCharacterVerification] = useState(false);
  const [passwordLengthVerification, setPasswordLengthVerification] = useState(false);

  useEffect(() => {
    setPasswordLengthVerification(
      watchPassword.length >= PASSWORD_LENGTH_STRENGTH,
    );

    // Contains a number or symbol
    setPasswordCharacterVerification(
      /[0-9!%@#$^*?_~&()]/.test(watchPassword),
    );
    const calculatePasswordStrength = () => {
      let strLvl = 1;

      if (passwordLengthVerification) {
        strLvl += 1;
      }

      if (passwordCharacterVerification) {
        strLvl += 1;
      }

      switch (strLvl) {
        case 1:
          return PASSWORD_STRENGTH.WEAK;
        case 2:
          return PASSWORD_STRENGTH.AVERAGE;
        case 3:
          return PASSWORD_STRENGTH.STRONG;
        default:
          return PASSWORD_STRENGTH.WEAK;
      }
    };
    setPasswordStrength(calculatePasswordStrength);
  }, [
    watchPassword,
    passwordCharacterVerification,
    passwordLengthVerification,
  ]);

  const onSubmit = (values: SubmitValues) => {
    if (watchPassword !== watchConfirmPassword) {
      setError('confirmPassword', { message: 'Your passwords do not match' });
      return;
    }
    setPassword(values.password);
    setStep(PAGE_STATE.SETUP_PROFILE);
  };

  return (
    <>
      <div className={styles.infoText}>
        <p>STEP 1/3</p>
        <h1>Create account</h1>
      </div>
      <form className={styles.formContainer} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.emailWrap}>
          <label htmlFor="email-input" className={styles.inputFieldLabel}>
            Your Email Address
            <input
              id="email-input"
              disabled
              data-testid="email-input"
              className={emailInputClass}
              value={email}
              {...register('email')}
            />
          </label>
          <Tooltip title={EMAIL_LOCK_TOOLTIP}>
            <LockOutlined className={styles.lockIcon} />
          </Tooltip>
          <div className={styles.invalidMessage}>
            {emailValidationError && 'Could not retrieve email'}
          </div>
        </div>
        <div className={styles.passwordWrap}>
          <label htmlFor="create-password" id="createPassword" className={styles.inputFieldLabel}>
            Create Password
            <input
              id="create-password"
              aria-labelledby="createPassword"
              type={showPassword ? 'text' : 'password'}
              className={passwordInputClass}
              data-testid="password-input"
              {...register('password', { required: true, minLength: PASSWORD_LENGTH_STRENGTH })}
            />
            <button
              className={styles.showPasswordButton}
              onClick={() => setShowPassword((prevState) => !prevState)}
              type="button"
            >
              {showPassword ? 'Hide' : 'Show'}
            </button>
          </label>
        </div>
        <Collapse in={dirtyFields.password}>
          <div className={styles.passwordStrength}>
            <p className={styles.level}>
              Password Strength:
              {' '}
              {passwordStrength}
            </p>
            <PasswordStrengthSpec
              label={`At least ${PASSWORD_LENGTH_STRENGTH} characters`}
              success={passwordLengthVerification}
            />
            <PasswordStrengthSpec
              label="Contains a number or symbol (ex: #, $, , !)"
              success={passwordCharacterVerification}
            />
          </div>
        </Collapse>
        <div className={styles.invalidMessage}>
          {dirtyFields.password
            && watchPassword.length === 0
            && 'Password cannot be blank'}
        </div>
        <div className={styles.passwordWrap}>
          <label htmlFor="confirm-password" id="confirmPassword" className={styles.inputFieldLabel}>
            Confirm Password
            <input
              id="confirm-password"
              aria-labelledby="confirmPassword"
              type={showConfirmPassword ? 'text' : 'password'}
              className={passwordInputClass}
              data-testid="confirm-input"
              {...register('confirmPassword', { required: true })}
            />
            <button
              className={styles.showPasswordButton}
              onClick={() => setShowConfirmPassword((prevState) => !prevState)}
              type="button"
            >
              {showConfirmPassword ? 'Hide' : 'Show'}
            </button>
          </label>
        </div>
        <div className={styles.invalidMessage}>
          {dirtyFields.confirmPassword
            && watchConfirmPassword.length === 0
            && 'Password cannot be blank'}
          {errors?.confirmPassword && errors.confirmPassword.message}
        </div>
        <div className={styles.tosTxt}>
          <p>
            By selecting ‘Next’ you accept the WHOOP
            {' '}
            <Link
              style={{ color: 'white' }}
              to="https://www.whoop.com/privacy/"
              target="_blank"
            >
              Privacy Policy
            </Link>
            {' '}
            and
            {' '}
            <Link
              style={{ color: 'white' }}
              to="https://www.whoop.com/termsofuse/"
              target="_blank"
            >
              Terms of Use
            </Link>
            .
          </p>
        </div>
        <Button
          label="next"
          aria-label="Next"
          variant="primary"
          theme="enterprise"
          type="submit"
          data-testid="next-btn"
          disabled={!passwordLengthVerification
            || !passwordCharacterVerification
            || !isValid
            || emailValidationError}
        />
      </form>
    </>
  );
}

export default CreateAccount;
